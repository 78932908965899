import React from "react";
// import PropTypes from 'prop-types';
import CircularProgress from "@material-ui/core/CircularProgress";
import Background from "../Background/Background";
// import "../../assets/scss/style.scss";
import "./Loader.scss";

function Loader({message}) {
  return (
    <>
      <Background />
      <div className="row col-lg-3 col-md-10 col-sm-10 col-12 m-auto  white-screen">
        <div className="loader m-auto">
          <CircularProgress disableShrink />
          <h2 className="heading-text mt-5"> {message}</h2>
        </div>
      </div>
    </>
  );
}

Loader.propTypes = {};

export default Loader;
