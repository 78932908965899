export const STATUS_CONNECTED = "connected";
export const STATUS_FAILED = "failed";
export const STATUS_ERROR_INFO = "errorinfo";
export const STATUS_RETRY = "retry"
export const STATUS_INFO_REQUIRED = "statusinforequired";
export const CONNECTIONS = "connections";
export const INTERNET_ERROR = "interneterror";
export const PLATFORM_SCREEN = "platformscreen";
export const SOMETHING_WENT_WRONG = "something-went-wrong";
export const LOADER = "loader";
export const LOADER_WITH_STATUS = "loaderwithstatus";
export const TERMINATE_POPUP = "teminatepopup";
export const INFO_SCREEN = "infoscreen";
export const EMPTY_SEARCH = "emptysearch";
export const DISCONNECT_ACCOUNT = "disconnectaccount";
export const INSTA_AUTH1 = "instaauthone";
export const INSTA_AUTH2 = "instaauthtwo";
export const FACEBOOK_AUTH = "facebookauth";
export const FACEBOOK_IOS_AUTH = "facebookiosauth";
export const BEEHIIV_AUTH = "beehiivauth";
export const BEEHIIV_SELECT_ACCOUNT = "beehiivselectacc";
export const BEEHIIV_LONG_LOADER = "beehiivlongloader";
export const LINKEDIN_PROFILE_AUTH = "linkedinprofileauth";
