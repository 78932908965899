import React from "react";
import { Route, Navigate, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {
    STATUS_CONNECTED,
    STATUS_FAILED,
    CONNECTIONS,
    INTERNET_ERROR,
    PLATFORM_SCREEN,
    SOMETHING_WENT_WRONG,
    LOADER,
    LOADER_WITH_STATUS,
    TERMINATE_POPUP,
    INFO_SCREEN,
    EMPTY_SEARCH,
    DISCONNECT_ACCOUNT,
    INSTA_AUTH1,
    INSTA_AUTH2,
    STATUS_ERROR_INFO,
    BEEHIIV_AUTH,
    BEEHIIV_SELECT_ACCOUNT,
    LINKEDIN_PROFILE_AUTH,
    BEEHIIV_LONG_LOADER,
    STATUS_INFO_REQUIRED,
    FACEBOOK_AUTH,
    STATUS_RETRY,
    FACEBOOK_IOS_AUTH,
} from "../../constants/URLConstants";
import IntroScreen from "../IntroScreen/IntroScreen";
import LoaderWithStatus from "../../components/LoaderWithStatus/LoaderWithStatus";
import SomethingWentWrong from "../SomethingWentWrong/SomethingWentWrong";
import { componentLoader } from "../../utils/sdk_utils";
import BeeHiivAuth from "../BeeHiivAuth/BeeHiivAuth";
import BeeHiivSelectAccount from "../BeeHiivSelectAccount/BeeHiivSelectAccount";
import LinkedinProfileAuth from "../LinkedinProfileAuth/LinkedinProfileAuth";
import BeeHiivLongLoader from "../BeeHiivLongLoader/BeeHiivLongLoader";
import StatusInfoRequired from "../StatusInfoRequired/StatusInfoRequired";
import FacebookAuthScreen from "../FacebookAuth/FacebookAuthScreen";
import FacebookIOSAuthScreen from "../FacebookAuth/FacebookIOSAuthScreen";

const StatusErrorInfo = React.lazy(() => componentLoader(() => import("../StatusErrorInfo/StatusErrorInfo"), 3));
const TerminatePopup = React.lazy(() => componentLoader(() => import("../TerminatePopup/TerminatePopup"), 3));
const LoginMFA = React.lazy(() => componentLoader(() => import("../LoginMFA/LoginMFA"), 3));
const ResetPassword = React.lazy(() => componentLoader(() => import("../ResetPassword/ResetPassword"), 3));
const ScraperPoller = React.lazy(() => componentLoader(() => import("../ScraperPoller/ScraperPoller"), 3));
const PlatformStatus = React.lazy(() => componentLoader(() => import("../PlatformStatus/PlatformStatus"), 3));
const PlatformScreen = React.lazy(() => componentLoader(() => import("../PlatformScreen/PlatformScreen"), 3));
const PreviousConnections = React.lazy(() => componentLoader(() => import("../PreviousConnections/PreviousConnections"), 3));
const StatusConnected = React.lazy(() => componentLoader(() => import("../StatusConnected/StatusConnected"), 3));
const DisconnectAccount = React.lazy(() => componentLoader(() => import("../DisconnectAccount/DisconnectAccount"), 3));
const StatusFailed = React.lazy(() => componentLoader(() => import("../StatusFailed/StatusFailed"), 3));
const StatusRetry = React.lazy(() => componentLoader(() => import("../StatusRetry/StatusRetry"), 3));
const InternetError = React.lazy(() => componentLoader(() => import("../InternetError/InternetError"), 3));
const InfoScreen = React.lazy(() => componentLoader(() => import("../InfoScreen/InfoScreen"), 3));
const InstagramAuthScreen1 = React.lazy(() => componentLoader(() => import("../InstagramFlowScreens/InstagramAuthScreen1"), 3));
const InstagramAuthScreen2 = React.lazy(() => componentLoader(() => import("../InstagramFlowScreens/InstagramAuthScreen2"), 3));
const ScraperConsent = React.lazy(() => componentLoader(() => import("../ScraperConsent/ScraperConsent"), 3));
const ScraperFlowFailed = React.lazy(() => componentLoader(() => import("../ScraperFlowFailed/ScraperFlowFailed"), 3));
const ScraperLogin = React.lazy(() => componentLoader(() => import("../ScraperLogin/ScraperLogin"), 3));
const ScraperMagicLink = React.lazy(() => componentLoader(() => import("../ScraperMagicLink/ScraperMagicLink"), 3));

function URL() {
    return (
        <React.Suspense fallback={<Loader />}>
            <BrowserRouter>
                <Routes>
                    <Route path={`/${PLATFORM_SCREEN}`} element={<PlatformScreen />} />
                    <Route path="/consent/:workPlatformId" element={<ScraperConsent />} />
                    <Route path="/poll/:loginSessionId" element={<ScraperPoller />} />
                    <Route path="/failed_to_connect/:loginSessionId" element={<ScraperFlowFailed />} />
                    <Route path="/mfa/" element={<LoginMFA />} />
                    <Route path="/magic-link/" element={<ScraperMagicLink/>} />
                    <Route path="/login/:workPlatformId" element={<ScraperLogin />} />
                    <Route path={`/${CONNECTIONS}/:workplatformid`} element={<PreviousConnections />} />
                    <Route path={`/${STATUS_CONNECTED}/:accountid`} element={<StatusConnected />} />
                    <Route path="/reset-password/" element={<ResetPassword />} />
                    <Route path={`/${STATUS_FAILED}/:authid`} element={<StatusFailed />} />
                    <Route path={`/${STATUS_ERROR_INFO}/:authid`} element={<StatusErrorInfo />} />
                    <Route path={`/${STATUS_RETRY}/:authid`} element={<StatusRetry />} />
                    <Route path={`/${STATUS_INFO_REQUIRED}/:authorizationid`} element={<StatusInfoRequired />} />
                    <Route path={`/${INTERNET_ERROR}`} element={<InternetError />} />
                    <Route path={`/${SOMETHING_WENT_WRONG}`} element={<SomethingWentWrong />} />
                    <Route path={`/${TERMINATE_POPUP}`} element={<TerminatePopup />} />
                    <Route path={`/${DISCONNECT_ACCOUNT}/:id`} element={<DisconnectAccount />} />
                    <Route path={`/${LOADER}`} element={<Loader />} />
                    <Route path={`/${LOADER_WITH_STATUS}`} element={<LoaderWithStatus />} />
                    <Route path="/status" element={<PlatformStatus />} />
                    <Route path={`/${INSTA_AUTH1}`} element={<InstagramAuthScreen1 />} />
                    <Route path={`/${INSTA_AUTH2}`} element={<InstagramAuthScreen2 />} />
                    <Route path={`/${FACEBOOK_AUTH}`} element={<FacebookAuthScreen />} />
                    <Route path={`/${FACEBOOK_IOS_AUTH}`} element={<FacebookIOSAuthScreen />} />
                    <Route path={`/${INFO_SCREEN}`} element={<InfoScreen />} />
                    <Route path={`/${BEEHIIV_AUTH}/:workplatformid`} element={<BeeHiivAuth />} />
                    <Route path={`/${BEEHIIV_LONG_LOADER}`} element={<BeeHiivLongLoader />} />

                    <Route path={`/${BEEHIIV_SELECT_ACCOUNT}`} element={<BeeHiivSelectAccount />} />
                    <Route path={`/${LINKEDIN_PROFILE_AUTH}/:authorizationid`} element={<LinkedinProfileAuth />} />
                    <Route path="/" element={<IntroScreen />} />

                    <Route path="*" element={<IntroScreen />} />
                </Routes>
            </BrowserRouter>
        </React.Suspense>
    );
}

export default URL;
