export const CONSENT_VIEW = "CONSENT_VIEW";
export const REDIRECT = "REDIRECT";
export const CONNECT_INITIALIZE_METHOD = "CONNECT_INITIALIZE_METHOD";
export const CONNECT_OPEN_METHOD = "CONNECT_OPEN_METHOD";
export const CONNECT_MODAL_OPEN = "CONNECT_MODAL_OPEN";
export const CONNECT_MODAL_CLOSE = "CONNECT_MODAL_CLOSE";
export const BACK_ARROW_CLICK = "BACK_ARROW_CLICK";
export const CONSENT_ACCEPT = "CONSENT_ACCEPT";
export const PRIVACY_POLICY_CLICK = "PRIVACY_POLICY_CLICK";
export const PLATFORM_GRID_VIEW = "PLATFORM_GRID_VIEW";
export const INFO_CLICK = "INFO_CLICK";
export const SDK_CLOSE_INITIATE = "SDK_CLOSE_INITIATE";
export const SDK_CLOSE_VIEW = "SDK_CLOSE_VIEW";
export const SDK_CLOSE_CONFIRM = "SDK_CLOSE_CONFIRM";
export const SDK_CLOSE_BACK = "SDK_CLOSE_BACK";
export const PLATFORM_SEARCH_ENTER = "PLATFORM_SEARCH_ENTER";
export const PLATFORM_LINK_CLICK = "PLATFORM_LINK_CLICK";
export const PLATFORM_SCREEN_VIEW = "PLATFORM_SCREEN_VIEW";
export const BACK_BUTTON_CLICK = "BACK_BUTTON_CLICK";
export const LOGIN_BUTTON_CLICK = "LOGIN_BUTTON_CLICK";
export const ACCOUNT_LINK_INITIATE = "ACCOUNT_LINK_INITIATE";
export const PLATFORM_POPUP_OPEN = "PLATFORM_POPUP_OPEN";
export const PLATFORM_POPUP_CLOSE = "PLATFORM_POPUP_CLOSE";
export const ACCOUNT_LINK_FAILURE = "ACCOUNT_LINK_FAILURE";
export const ACCOUNT_LINK_TRY_AGAIN = "ACCOUNT_LINK_TRY_AGAIN";
export const ACCOUNT_LINK_ADD_ACCOUNT = "ACCOUNT_LINK_ADD_ACCOUNT";
export const ACCOUNT_LINK_SUCCESS = "ACCOUNT_LINK_SUCCESS";
export const ACCOUNT_LINK_DONE = "ACCOUNT_LINK_DONE";
export const DISCONNECT_ACCOUNT_CLICK = "DISCONNECT_ACCOUNT_CLICK";
export const DISCONNECT_ACCOUNT_CONFIRM = "DISCONNECT_ACCOUNT_CONFIRM";
export const DISCONNECT_ACCOUNT_SUCCESS = "DISCONNECT_ACCOUNT_SUCCESS";
export const DISCONNECT_ACCOUNT_FAILURE = "DISCONNECT_ACCOUNT_FAILURE";
export const DISCONNECT_ACCOUNT_BACK = "DISCONNECT_ACCOUNT_BACK";
export const INSTAGRAM_SPECIAL_REQUIREMENT_VIEW = "INSTAGRAM_SPECIAL_REQUIREMENT_VIEW";
export const INSTAGRAM_PROFESSIONAL_ACCOUNT_YES = "INSTAGRAM_PROFESSIONAL_ACCOUNT_YES";
export const INSTAGRAM_PROFESSIONAL_ACCOUNT_NO = "INSTAGRAM_PROFESSIONAL_ACCOUNT_NO";
export const INSTAGRAM_FBPAGE_LINKED_YES = "INSTAGRAM_FBPAGE_LINKED_YES";
export const INSTAGRAM_FBPAGE_LINKED_NO = "INSTAGRAM_FBPAGE_LINKED_NO";
export const HELP_GUIDE_CLICK = "HELP_GUIDE_CLICK";
export const CONNECT_INSTAGRAM_VIA_FB_CLICK = "CONNECT_INSTAGRAM_VIA_FB_CLICK";
export const SCRAPER_CONSENT_VIEW = "SCRAPER_CONSENT_VIEW";
export const SCRAPER_CONSENT_ACCEPT = "SCRAPER_CONSENT_ACCEPT";
export const SCRAPER_LOGIN_PASSWORD_VIEW = "SCRAPER_LOGIN_PASSWORD_VIEW";
export const SCRAPER_PASSWORD_LOGIN_CLICK = "SCRAPER_PASSWORD_LOGIN_CLICK";
export const SCRAPER_LOGIN_PASSWORD_REVEAL = "SCRAPER_LOGIN_PASSWORD_REVEAL";
export const SCRAPER_LOGIN_PASSWORD_HIDE = "SCRAPER_LOGIN_PASSWORD_HIDE";
export const SCRAPER_SHORT_LOADER_VIEW = "SCRAPER_SHORT_LOADER_VIEW";
export const SCRAPER_LONG_LOADER_VIEW = "SCRAPER_LONG_LOADER_VIEW";
export const SCRAPER_LONG_LOADER_VIEW_END = "SCRAPER_LONG_LOADER_VIEW_END";
export const SCRAPER_LOGIN_PASSWORD_ERROR = "SCRAPER_LOGIN_PASSWORD_ERROR";
export const SCRAPER_LOGIN_2FA_VIEW = "SCRAPER_LOGIN_2FA_VIEW";
export const SCRAPER_LOGIN_2FA_ERROR = "SCRAPER_LOGIN_2FA_ERROR";
export const SCRAPER_LOGIN_2FA_PROCEED = "SCRAPER_LOGIN_2FA_PROCEED";
export const SCRAPER_LOGIN_FORGOT_PASSWORD_CLICK = "SCRAPER_LOGIN_FORGOT_PASSWORD_CLICK";
export const SCRAPER_RESET_PASSWORD_VIEW = "SCRAPER_RESET_PASSWORD_VIEW";
export const SCRAPER_RESET_PASSWORD_LINK_CLICK = "SCRAPER_RESET_PASSWORD_LINK_CLICK";
export const SCRAPER_BACK_TO_LOGIN_CLICK = "SCRAPER_BACK_TO_LOGIN_CLICK";

export const CONNECT_INITIATED = "CONNECT_INITIATED";
export const CONNECT_CONSENT_ACCEPT = "CONNECT_CONSENT_ACCEPT";

export const PLATFORM_SEARCH_TAP = "PLATFORM_SEARCH_TAP";
export const PLATFORM_LINK_CLICKED = "PLATFORM_LINK_CLICKED";
export const PLATFORM_LINK_BACK_CLICKED = "PLATFORM_LINKBACK_CLICKED";
export const ACCOUNT_LINK_BACK_CLICKED = "ACCOUNT_LINK_BACK_CLICKED";
export const ACCOUNT_LINK_INITIATED = "ACCOUNT_LINK_INITIATED";
export const ACCOUNT_LINK_SUCCESS_FROM_FAILURE = "ACCOUNT_LINK_SUCCESS_FROM_FAILURE";
export const ACCOUNT_LINK_RETRY = "ACCOUNT_LINK_RETRY"
export const DISCONNECT_ACCOUNT_ABANDON = "DISCONNECT_ACCOUNTABANDON";
export const SDK_BACK_CLICK = "SDK_BACK_CLICK";
export const INSTAGRAM_HELP_GUIDE_CLICK = "INSTAGRAM_HELP_GUIDE_CLICK";
export const ERROR_INFO_SCREEN = "ERROR_INFO_SCREEN";
export const ACCOUNT_RETRY_SCREEN = "ACCOUNT_RETRY_SCREEN";
export const INSTAGRAM_INFO_Q1 = "INSTAGRAM_INFO_Q1";
export const INSTAGRAM_INFO_Q2 = "INSTAGRAM_INFO_Q2";
export const FB_QUESTION_YES = "FB_QUESTION_YES";
export const FB_QUESTION_NO = "FB_QUESTION_NO";
export const FB_HOW_TO = "FB_HOW_TO";
export const FB_LOGIN = "FB_LOGIN";
export const FB_PROCEED_TO_LOGIN = "FB_PROCEED_TO_LOGIN";
export const FB_BACK = "FB_BACK";

// screen names
export const CONSENT = "consent";
export const INFO = "info";
export const PLATFORM_GRID = "platform_grid";
export const PLATFORM = "platform_";
export const CONNECTING_TO_PLATFORM = "connecting_platform_";
export const CONNECTION_SUCCESS = "connection_success";
export const CONNECTION_FAILURE = "connection_failure";
export const CONNECTION_FAILURE_INFO = "connection_failure_info";
export const CLOSE_CONFIRMATION = "close_confirm";
export const DISCONNECT_CONFIRMATION = "account_disconnect_confirm";
export const INSTAGRAM_SPECIAL_REQUIREMENT_1 = "instagram_special_requirement_1";
export const INSTAGRAM_SPECIAL_REQUIREMENT_2 = "instagram_special_requirement_2";
export const FACEBOOK_SPECIAL_REQUIREMENTS = "facebook_special_requirements";
export const FACEBOOK_SPECIAL_REQUIREMENTS_IOS = "facebook_special_requirements_ios";
export const SCRAPER_CONSENT = "scraper_consent";
export const SCRAPER_LOGIN_PASSWORD = "scraper_login_password";
export const SCRAPER_LOGIN_MAGIC_LINK = "scraper_login_magic_link";
export const SCRAPER_LOGIN_OTP = "scraper_login_otp";
export const SCRAPER_LOGIN_2FA_OTP = "scraper_2fa_otp";
export const SCRAPER_MAGIC_LINK = "scraper_magic_link";
export const SCRAPER_LOGIN_2FA_AUTHENTICATOR = "scraper_2fa_authenticator";
export const SCRAPER_RESET_PASSWORD = "scraper_reset_password";
export const SCRAPER_SHORT_LOADER = "scraper_short_loader";
export const SCRAPER_LONG_LOADER = "scraper_long_loader";
export const BEEHIIV_AUTH_SCREEN = "beehiiv_auth_screen";
export const BEEHIIV_SELECT_ACC_SCREEN = "beehiiv_select_acc_screen";
export const BEEHIIV_LONG_LOADER_SCREEN = "beehiiv_long_loader_screen";
export const LINKEDIN_PROFILE_AUTH_SCREEN = "linkedin_profile_auth_screen";
