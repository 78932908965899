import { LANGUAGES } from "../../constants/constants";

export const LoadingStates = {
    pending: "pending",
    loading: "loading",
    completed: "completed",
};

export const AllAccountsActions = {
    addAccount: "ADD_ACCOUNT",
    addAllAccounts: "ADD_ALL_ACCOUNTS",
    setLoadingTrue: "SET_LOADING_TRUE",
};

export const SDKInstanceCtxActions = {
    setData: "SET_DATA",
};

export const ConfigActions = {
    setData: "SET_DATA",
};

export const SDKParamsActions = {
    addParams: "ADD_PARAMS",
    incrementRetry: "INCREMENT_RETRY"
};

export const WorkPlatformDetailsActions = {
    addWorkPltrm: "ADD_WORKPLATFORM",
};

export const AllAccountsReducer = (state, action) => {
    switch (action.type) {
        case AllAccountsActions.addAllAccounts:
            return {
                ...state,
                isLoading: LoadingStates.completed,
                data: action.payload,
            };
        case AllAccountsActions.setLoadingTrue:
            return {
                ...state,
                isLoading: LoadingStates.loading,
            };
        default:
            return state;
    }
};

export const SDKInstanceReducer = (state, action) => {
    switch (action.type) {
        case SDKInstanceCtxActions.setData:
            return {
                ...state,
                isLoading: LoadingStates.completed,
                data: action.payload,
            };
        default:
            return state;
    }
};

export const ConfigReducer = (state, action) => {
    switch (action.type) {
        case ConfigActions.setData:
            return {
                ...state,
                isLoading: LoadingStates.completed,
                data: action.payload,
            };
        default:
            return state;
    }
};

export const SDKParamsReducer = (state, action) => {
    switch (action.type) {
        case SDKParamsActions.addParams:
            const sdkParams = {};
            sdkParams.env = action.payload.env;
            sdkParams.appName = action.payload.appName;
            sdkParams.workPlatformId = action.payload.workPlatformId;
            sdkParams.token = action.payload.token;
            sdkParams.userId = action.payload.userId;
            sdkParams.flow = action.payload.workPlatformId ? action.payload.workPlatformId : "null";
            sdkParams.version = action.payload.version;
            sdkParams.mobile = action.payload.mobile ? action.payload.mobile : false;
            sdkParams.mobileVersion = action.payload.mobileVersion ? action.payload.mobileVersion : "null";
            sdkParams.sdk = action.payload.sdk ? action.payload.sdk : "null";
            sdkParams.redirectURL = action.payload.redirectURL ? action.payload.redirectURL : "null";
            sdkParams.singleAccount = action.payload.singleAccount ? action.payload.singleAccount : false;
            sdkParams.language = action.payload.language ? action.payload.language : LANGUAGES.english[0];
            // console.log(action);
            return {
                ...state,
                isLoading: LoadingStates.completed,
                data: sdkParams,
            };
        case SDKParamsActions.incrementRetry:
            return {
                ...state,
                connectionRetryCount: state.connectionRetryCount + 1
            };
        default:
            return state;
    }
};

export const WorkPlatformDetailsReducer = (state, action) => {
    switch (action.type) {
        case WorkPlatformDetailsActions.addWorkPltrm:
            return {
                ...state,
                isLoading: LoadingStates.completed,
                data: action.payload,
            };
        default:
            return state;
    }
};
